import 'antd/dist/antd.css';
import { useEffect } from 'react';
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';
import './App.scss';
import './antd-custom.scss';
import { REACT_APP_GA, REDIRECTURL } from './constants/config';
import './helper.scss';
import Layout from './pages/Layout';
import Router from './router/Router';
import { successNotification } from './components/UI/Toast/Toast';
if (REACT_APP_GA) {
  ReactGA.initialize(REACT_APP_GA);
}

function App(props: any) {
  const location = useLocation(),
  corporateLink = ['healthysure-employee-wellness'] as any;

  useEffect(() => {
    // trackData('View Page', { url: location.pathname });
    const pathMatch = location.pathname.match(/^\/([^\/]*)/); // Extract the first path segment
    if (pathMatch && pathMatch[1]) {
      let newPath = `${REDIRECTURL}/${pathMatch[1]}`;
      if(corporateLink?.includes(pathMatch[1])){
        newPath = `https://www.app.healthysure.in/retail`;
        successNotification('Please login to access the offers...')
      }
      else{
        successNotification('Taking you to the offers page...')
      }
      setTimeout(() => {
        window.location.href = newPath; // Redirect to the external URL
      }, 750);
    }
  }, [location]);

  return (
    <Layout {...props}>
      <Router />
    </Layout>
  );
}

export default App;
