export const CLIENT_ID = (window as any).env.CLIENT_ID;
export const CLIENT_SECRET = (window as any).env.CLIENT_SECRET;
export const MAIN_SITE_URL = 'https://www.healthysure.in';
export const MAIN_SITE_URL_ENQUIRY = 'https://healthysure.in/how-it-works/?source=HnW&campaign_id=';
export const BRAND_NAME = 'Healthysure';
export const GOOGLE_ANALYTICS_TRACKING_CODE = 'G-2MM8BS6NHL';
export const REACT_APP_GA = process.env.REACT_APP_GA || (window as any).env.REACT_APP_GA;
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || (window as any).env.REACT_APP_MIXPANEL_TOKEN;

export const COUNTRY_CODE = '+91';
let API_URL = (window as any).env.API_URL;
export const CASHFREE_URL = (window as any).env.CASHFREE_URL;
export const HDFCCOUPON = 'HDFCSURE';
export const DEXTERCOUPON = 'DEXTER2023';
export const REDIRECTURL = 'https://www.app.bharatsure.com/retail-partner';
let DEXTERSLUG = 'dexter-angels';

if (!API_URL) {
  if (window.location.hostname === 'localhost') {
    API_URL = '';
  } else {
    API_URL = window.location.protocol + '//' + window.location.hostname.replace('portal', 'api') + '/';
  }
}

export { API_URL, DEXTERSLUG };

