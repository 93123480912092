import { Col, Image, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import api from '../api/api';
import checkmarkfilled from '../assets/icons/checkmark-filled.svg';
import providerlogo from '../assets/images/providerlogo.svg';
import LockModal from '../components/Modal/LockModal';
import PaymentFailure from '../components/PaymentFailureScreen/PaymentFailure';
import ProductCouponCard from '../components/ProductCouponCard/ProductCouponCard';
import { trackData } from '../utils/trackers';


const partners = [
  { logo: providerlogo, name: 'Visit' },
  { logo: providerlogo, name: 'Visit' },
  { logo: providerlogo, name: 'Visit' },
  { logo: providerlogo, name: 'Visit' },
]

declare global {
  interface Window {
    pagesense: any[];
  }
}
const PostPayment: React.FC = () => {
  const navigate = useNavigate(),
    [searchParams, setSearchParams] = useSearchParams(),
    [paymentStatus, setPaymentStatus] = useState(null) as any,
    [purchaseData, setPurchaseData] = useState([] as any),
    { partner } = useParams(),
    getPaymentStatus = () => {
      api.payments.getPaymentStatus({ order_id: searchParams.get('order_id'), slug: partner })
        .then((result) => {
          if (!result?.is_retry) {
            setPaymentStatus(result?.is_payment_done ? true : false);
            if (result?.is_payment_done) {
              api.coupons.purchasedCoupons({ slug: partner })
                .then((res) => {
                  // console.log(res);
                  // console.log(res?.data?.map((item: any) => ({ item_name: item?.product_name, item_variant: item?.plan_code, item_brand: item?.provider_name, price: item?.offer_cost, quantity: item?.quantity })))
                  ReactGA.event('purchase', {
                    value: result?.amount, currency: 'INR',
                    items: res?.data?.map((item: any) => ({ item_name: item?.product_name, item_variant: item?.plan_code, item_brand: item?.provider_name, price: item?.offer_cost, quantity: item?.quantity }))
                  });
                  trackData('Post Payment', { value: result?.amount, currency: 'INR', items: res?.data?.map((item: any) => ({ item_name: item?.product_name, item_variant: item?.plan_code, item_brand: item?.provider_name, price: item?.offer_cost, quantity: item?.quantity })) });
                  setPurchaseData(res?.data);
                })
                .catch((err) => {
                  setPaymentStatus(false);
                  console.log(err);
                });
            }
          }
          else {
            getPaymentStatus();
          }
        }
        )
        .catch((err) => {
          setPaymentStatus(false);
          console.log(err);
        })

    };

  useEffect(() => {
    if (searchParams.get('order_id')) {
      window.scrollTo(0, 0)
      getPaymentStatus();
      window.pagesense = window.pagesense || [];
      window.pagesense.push(['trackEvent', 'Visits Post Payment Page']);
    }
    else {
      navigate(`/${partner}`);
    }
  }, [searchParams.get('order_id')]);

  return (
    <div className="page">
      <LockModal />
      <div className='container'>
        <Row className='post-payment-card'>
          {paymentStatus === true && <Col>
            <div className='secondary-heading'>
              <Image preview={false} src={checkmarkfilled} width={28} height={28} />&nbsp;
              Your payment is successful</div>
            <div className='mt-16'>Below is your purchase summary. Please check your inbox for the coupon(s) and details of how to avail the benefits.</div>
          </Col>}
          {paymentStatus === null &&
            <Col>
              <div className='secondary-heading'>
                <Spin size='small' />&nbsp;
                Checking payment status</div>
              <div className='mt-16'>Hang on a second.....</div>
            </Col>
          }
        </Row>

        <Row justify='center'>
          {paymentStatus === false && <PaymentFailure />}
        </Row>


        {paymentStatus === true && <Row className='mt-48' gutter={24}>
          {purchaseData?.map((product: any, index: number) => (
            <Col span={24} key={index}>
              <ProductCouponCard data={product} />
            </Col>
          ))}
        </Row>}

        <div className='blue-cta mt-48 text-center' onClick={() => navigate(`/${partner}`)}>Back to Home</div>
      </div>
    </div>
  )
};

export default PostPayment;
