import { API_URL } from '../constants/config';
const apiEndpoints = {
  authenticate: API_URL + 'profile/login',
  refreshToken: API_URL + 'auth/token',
  userProfile: API_URL + 'partners/partner-member-profile',
  getPartner: API_URL + 'partners/partner-provider',
  getProducts: API_URL + 'partners/partner-product',
  paymentRequest: API_URL + 'billing/partner_payment_order',
  paymentStatus: API_URL + 'billing/partner_verify_payment_order',
  manageCart: API_URL + 'carts/cart-item',
  cartCount: API_URL + 'carts/cart-items-count',
  requestOTP: API_URL + 'profile/request-otp',
  verifyOTP: API_URL + 'profile/signup-login-via-otp',
  purchasedCoupons: API_URL + 'partners/partner-member-coupon',
  partnerDetails: API_URL + 'partners/partner-details',
};

export default apiEndpoints;
